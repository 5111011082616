<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in primaryPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'executor_primary'"
      />
      <ProffesionalExecutorPerson v-if="professionalExecutorsAndFamily" />
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :show="show.personModal"
        :person="selectedPerson"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'
import ProffesionalExecutorPerson from './Helpers/ProfessionalExecutorPerson'

export default {
  name: 'ExecutorsPrimary',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad,
    ProffesionalExecutorPerson
  },
  computed: {
    heading() {
      if (this.professionalExecutorsAndFamily)
        return 'Who would you like to name as your executor(s)?'
      return 'Who would you like to name as your main executor(s)?'
    },
    number() {
      if (
        this.partner &&
        !this.partner.executor_sole_primary &&
        !this.professionalExecutorsAndFamily
      )
        return '3'
      return '2'
    },
    subHeading() {
      var string =
        'You must appoint at least one person; although it is possible to have two or more Executors.'
      if (this.professionalExecutorsAndFamily)
        string += ' These will work along side our professional executors.  '
      string +=
        'People often appoint their close family members or friends in this role.  '
      if (this.youngChildren.length)
        string +=
          'Executors must be over the age of 18, when ' +
          this.listPeople(this.youngChildren, ' and ', true)
      string += this.youngChildren.length > 1 ? ' are ' : ' is '
      string +=
        ' older you may consider updating your Will and having them added as Executors.'
      return string
    },
    primaryPeople() {
      return this.$store.getters.people.filter(
        (person) =>
          !person.executor_secondary &&
          !person.under18 &&
          !person.executor_backup &&
          !person.executor_sole_primary
      )
    },
    youngChildren() {
      return this.people.filter((person) => person.child && person.under18)
    },
    people() {
      return this.$store.getters.people
    },
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    professionalExecutorsAndFamily() {
      return this.$store.getters.details.executor_professional_and_family
    },
    forwardTo() {
      if (this.professionalExecutorsAndFamily)
        return '/executors/check_and_confirm'
      if (this.partner && !this.partner.executor_sole_primary)
        return '/executors/secondary'
      else if (this.partner && this.partner.executor_sole_primary)
        return '/executors/check_and_confirm'
      else if (!this.pertner) return '/executors/secondary_option'
      return '/executors/secondary_option'
    },
    backTo() {
      if (this.professionalExecutorsAndFamily) return '/executors/type_of'
      if (this.partner) return '/executors/partner'
      return '/executors/type_of'
    },
    isValidated() {
      if (this.people.filter((person) => person.executor_primary).length)
        return true
      return false
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      var copyPerson = Object.assign({}, person)
      copyPerson.executor_primary = !person.executor_primary
      this.$store.commit('personAdd', copyPerson)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('executor_primary')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
