<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 option-padding">
    <div
      v-on:click="
        $router.push('/executors/type_of').catch((error) => {
          console.log(error)
        })
      "
      class="person-edit-dots"
    >
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <div class="input-option selected">
      <div class="tick-wrapper"></div>
      <span class="tick ticked"></span>

      <div class="image-wrapper">
        <img
          v-bind:src="'https://mylastwill.s3.amazonaws.com/static/img/button-pro-fam.png'"
          alt="mylastwill"
        />
      </div>
      <div class="person-details">
        <p>Professional Executor</p>
        <p class="relationship">(Provided by {{ professionalExecutors }})</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfessionalExecutorPerson',
  computed: {
    professionalExecutors() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.broker &&
        this.$store.getters.checkoutBasket.coupon.broker
          .alternative_professional_executors
      )
        return this.$store.getters.checkoutBasket.coupon.broker
          .alternative_professional_executors.company_name
      else {
        return 'MyLastWill'
      }
    }
  }
}
</script>
